<template>
  <v-container fluid class="pa-0 grey lighten-3">
    <v-main>
      <PanelHeader />
      <v-container class="pa-0 mt-n8">
        <v-row no-gutters>
          <v-col md="3">
            <Drawer />
          </v-col>
          <v-col cols="12" md="9">
            <router-view target="_blank" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-container>
</template>

<script>
import Drawer from "@/components/Panel/Drawer";
import PanelHeader from "@/components/Panel/PanelHeader";
import { PanelChildrens } from "@/router/Pages.js";
import MyAxios from "../../constants/MyAxios";

export default {
  watch: {
    $route: {
      immediate: true,
      handler(v) {
        const foundRoute = PanelChildrens.find((val) => v.name == val.name);
        const userRole =
          this.$store.state.userData?.roleName?.toLowerCase() || "";
        if (
          !(
            foundRoute &&
            this.$store.state.userData &&
            foundRoute?.roles?.some(
              (val) => val.toLowerCase() == userRole || userRole == "admin"
            )
          )
        ) {
          this.$root.$emit("toast", {
            text: "دسترسی به این بخش ندارید.",
          });
          this.$router.replace("/404");
        } else {
          if (this.$store.state.userNotificationsCount[foundRoute?.name])
            this.decreaseNotificationCount(foundRoute?.name);
        }
      },
    },
  },
  methods: {
    decreaseNotificationCount(name) {
      MyAxios.post("/notifications/items/read", {
        name,
      }).then((response) => {
        if (response) {
          let notificationsCount = {
            ...this.$store.state.userNotificationsCount,
          };
          notificationsCount[name] = 0;
          this.$store.commit("notificationsCount", notificationsCount);
        }
      });
    },
  },
  components: {
    Drawer,
    PanelHeader,
  },
};
</script>

<style></style>
