<template>
  <v-container v-if="$store.state.userData">
    <v-card
      v-if="$vuetify.breakpoint.mdAndUp"
      class="mt-n13 rounded-lg moholand-shadow"
    >
      <v-list>
        <v-list-item class="text-center justify-center align-center">
          <v-avatar
            size="150"
            style="border-radius: 20px;border: 4px solid #f66200"
            class="justify-center"
          >
            <v-img
              :src="
                $store.state.userData.avatar_url ||
                  require('@/assets/img/man.jpg')
              "
            ></v-img>
          </v-avatar>
        </v-list-item>

        <v-list-item class="text-center justify-center align-center">
          <h3
            v-if="
              $store.state.userData.first_name &&
                $store.state.userData.last_name
            "
          >
            {{
              $store.state.userData.first_name +
                " " +
                $store.state.userData.last_name
            }}
          </h3>
        </v-list-item>
        <div class="text-center">
          <small v-if="$store.state.userData">
            موجودی:
            {{ Intl.NumberFormat().format($store.state.userData.balance) }}
            تومان
          </small>
        </div>
      </v-list>
      <v-list nav dense class="d-flex flex-column">
        <v-btn
          class="rounded-lg my-1"
          large
          :color="
            $route.path.includes(
              item.path.includes('chaptersmanager')
                ? '/chaptersmanager'
                : item.path
            )
              ? 'green'
              : 'grey'
          "
          dark
          v-for="(item, i) in items"
          :key="i"
          @click="navigateTo(item.name)"
        >
          {{ item.title }}
          <v-spacer />
          <v-chip
            v-if="$store.state.userNotificationsCount[item.name]"
            class="red darken-2"
          >
            {{ $store.state.userNotificationsCount[item.name] }}
          </v-chip>
        </v-btn>
        <v-btn
          class="rounded-lg my-1"
          large
          color="orange darken-1"
          dark
          v-if="role == 'user'"
          to="/register?seller=true"
        >
          فروشنده شو!
          <v-spacer />
        </v-btn>
        <v-btn
          class="rounded-lg my-1"
          large
          color="orange darken-1"
          dark
          v-if="role == 'vendor' && $store.state.userData"
          :to="'/sellers/' + $store.state.userData.id"
        >
          فروشگاه من
          <v-spacer />
        </v-btn>
        <v-btn class="rounded-lg my-1" large color="red" dark @click="exit">
          خروج
          <v-spacer />
        </v-btn>
      </v-list>
    </v-card>
    <v-navigation-drawer v-else v-model="show" app right max-width="80%">
      <v-card elevation="0">
        <v-list>
          <v-list-item class="text-center justify-center align-center">
            <v-avatar
              size="150"
              style="border-radius: 20px;border: 4px solid #f66200"
              class="justify-center"
            >
              <v-img
                :src="
                  $store.state.userData.avatar_url ||
                    require('@/assets/img/man.jpg')
                "
              ></v-img>
            </v-avatar>
          </v-list-item>

          <v-list-item class="text-center justify-center align-center">
            <h3>
              {{
                $store.state.userData.first_name +
                  " " +
                  $store.state.userData.last_name
              }}
            </h3>
          </v-list-item>
        </v-list>
        <v-list nav dense class="d-flex flex-column">
          <v-btn
            class="rounded-lg my-1"
            large
            :color="$route.path.includes(item.path) ? 'green' : 'grey'"
            dark
            v-for="(item, i) in items"
            :key="i"
            @click="navigateTo(item.name)"
          >
            {{ item.title }}
            <v-spacer />
            <v-chip
              v-if="$store.state.userNotificationsCount[item.name]"
              class="red darken-2"
            >
              {{ $store.state.userNotificationsCount[item.name] }}
            </v-chip>
          </v-btn>
          <v-btn
            class="rounded-lg my-1"
            large
            color="orange darken-1"
            dark
            v-if="role == 'user'"
            to="/register?seller=true"
          >
            فروشنده شو!
            <v-spacer />
          </v-btn>
          <v-btn
            class="rounded-lg my-1"
            large
            color="orange darken-1"
            dark
            v-if="role == 'vendor' && $store.state.userData"
            :to="'/sellers/' + $store.state.userData.id"
          >
            فروشگاه من
            <v-spacer />
            <div />
          </v-btn>
          <v-btn class="rounded-lg my-1" large color="red" dark @click="exit">
            خروج
            <v-spacer />
          </v-btn>
        </v-list>
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { PanelChildrens } from "@/router/Pages";
import { hasPermission } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MySnackbar from "@/components/MySnackbar";

export default {
  name: "Drawer",
  components: {
    MySnackbar,
  },
  data() {
    return {
      role: this.$store.state?.userData?.roleName,
      alert: {
        show: false,
        text: "",
        type: "error",
      },
      show: this.$vuetify.breakpoint.mdAndUp,
      loading: false,
      items: PanelChildrens.filter(
        (value) =>
          value.show &&
          value.roles.includes(this.$store.state?.userData?.roleName)
      ),
      // .map((value) =>
      //   value.name == "Docs"
      //     ? {
      //         ...value,
      //         path:
      //           "/docs/" +
      //           this.$store.state.userData.id +
      //           "?type=" +
      //           (this.$store.state.userData.roleName == "player"
      //             ? 2
      //             : this.$store.state.userData.roleName == "coach"
      //             ? 1
      //             : 3),
      //       }
      //     : value
      // )
      // .filter(
      //   (v) =>
      //     !(
      //       this.$store.state.userData.roleName == "player" &&
      //       v.name == "TuitionsPlayer" &&
      //       !this.$store.state.userData.club
      //     )
      // ),
    };
  },
  methods: {
    toggle: function() {
      this.show = !this.show;
    },
    navigateTo: function(name) {
      // this.$router.push({ name });
      console.log(name);
      this.$router.push({ name });
    },
    exit: function() {
      this.$store.commit("exit");

      this.$router.replace("/");
    },
  },
  mounted() {
    console.warn("logggggggggggggggg", this.$route);
    this.$root.$on("toggleDrawer", () => {
      this.toggle();
    });
    this.$root.$on("updateDrawer", () => {
      this.updateDrawer();
    });
  },
};
</script>

<style scoped>
.menu-item:active {
  background: #69c263;
  color: #fff;
}
.menu-item {
  background: #7d7d7d;
}
.menu-item .v-list-item__title {
  font-size: 15px;
}
</style>
