<template>
  <v-row class="header-bg white--text px-4">
    <v-col
      cols="1"
      class="my-15 text-center"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-btn dark icon text class="text-center" @click="onMenuPressed">
        <v-icon x-large>
          mdi-menu
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="11" md="12" class=" my-15">
      <h2 class="text-center">{{ title }}</h2>
    </v-col>
    <v-btn
      dark
      icon
      text
      class="text-center ma-2"
      absolute
      style="left: 0; top: 0"
      to="/"
    >
      <v-icon large>
        mdi-home
      </v-icon>
    </v-btn>
  </v-row>
</template>

<script>
export default {
  methods: {
    onMenuPressed() {
      this.$root.$emit("toggleDrawer");
    },
  },
  computed: {
    title() {
      const role = this.$store.state.userData?.roleName || "";
      if (role == "admin") return "داشبورد ادمین";
      else if (role == "vendor") return "داشبورد فروشندگان";
      else if (role == "user") return "داشبورد کاربران";
      else return "داشبورد";
    },
  },
};
</script>

<style scoped>
.header-bg {
  height: 250px;
  background-image: url("../../assets/header.jpg");
  background-position: center bottom;
  background-size: cover;
}
</style>
